import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ServicesSection,
  ContactSection,
  DecorationSection,
  HeaderMain,
} from "../components/utility"

const IndexPage = () => (
  <Layout>
    <SEO title="Telecomhub" />
    <HeaderMain />
    <ServicesSection />
    <ContactSection />
    <DecorationSection />
  </Layout>
)

export default IndexPage
